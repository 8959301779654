import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { Spinner } from 'src/features/ui/components/Spinner';
import endpointConfigs from 'src/services/api/endpointConfigs';
import routeConfigs from './routeConfigs';

export type LoginRequiredProps = { children: JSX.Element };

export const LoginRequired = ({ children }: LoginRequiredProps): JSX.Element => {
  const router = useRouter();

  const query = useQuery({
    ...endpointConfigs.currentUser,
    retry: (_failureCount, error: AxiosError) => {
      if (error.response?.status === 403) {
        // eslint-disable-next-line no-void
        void router.push(routeConfigs.login);
        return false;
      }
      return true;
    },
  });

  return query.isSuccess ? children : <Spinner fullScreen />;
};
