import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Toast, toast } from 'react-hot-toast';
import { Button } from '../components/Button';

export type StyleDef = {
  titleClassName: string;
  borderClassName: string;
};

const styleDefs = {
  danger: { titleClassName: 'text-danger', borderClassName: 'border-danger' },
  success: { titleClassName: 'text-primary', borderClassName: 'border-primary' },
  loading: { titleClassName: 'text-white', borderClassName: 'border-white' },
} as const satisfies Record<string, StyleDef>;

export type ToastVariant = keyof typeof styleDefs;

export type ToastProps = { t: Toast; title: string; text: string; variant: ToastVariant };

export const LinguaToast = ({ t, title, text, variant }: ToastProps): JSX.Element => (
  <Transition
    show={t.visible}
    enter="transition-opacity duration-150"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-150"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div
      className={classNames(
        'flex flex-col gap-2',
        'ring-1 ring-grey-4',
        'pointer-events-auto relative min-w-[14rem] max-w-lg rounded-lg bg-grey-3 p-5 text-sm text-white',
        'border-l-8',
        styleDefs[variant].borderClassName,
      )}
    >
      <span className={classNames('mr-8 font-bold', styleDefs[variant].titleClassName)}>{title}</span>
      <span className="whitespace-pre-wrap">{text}</span>

      <Button
        icon="close"
        className="absolute top-2 right-2"
        variant="borderless"
        onClick={() => toast.dismiss(t.id)}
      />
    </div>
  </Transition>
);
