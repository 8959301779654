import { Button } from '../components/Button';

export type ModalProps = {
  children: { title: JSX.Element; body: JSX.Element; actions: JSX.Element };
  visible: boolean;
  setVisible: (value: boolean) => void;
};

export const Modal = ({ children, visible, setVisible }: ModalProps): JSX.Element =>
  visible ? (
    <div className="fixed top-0 left-0 z-50 flex min-h-screen min-w-full items-center justify-center bg-grey-3/50">
      <div className="relative flex flex-col items-center gap-10 rounded-lg bg-grey-1 p-10 ring-1 ring-grey-4">
        <Button
          icon="close"
          className="absolute top-2 right-2"
          variant="borderless"
          onClick={() => setVisible(false)}
        />
        <div className="text-center text-2xl">{children.title}</div>
        <div className="max-h-[32rem] overflow-auto">{children.body}</div>
        <div className="w-full">{children.actions}</div>
      </div>
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
