import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { withZodSchema } from 'formik-validator-zod';
import { useTranslation } from 'next-i18next';
import { Button } from 'src/features/ui/components/Button';
import { Dropdown } from 'src/features/ui/components/Dropdown';
import { Icon } from 'src/features/ui/components/Icon';
import { Modal } from 'src/features/ui/templates/Modal';
import endpointConfigs from 'src/services/api/endpointConfigs';
import { conciseErrorLinguaPromiseToast, conciseLinguaPromiseToast } from 'src/utils/functions/linguaToast';
import { z } from 'zod';
import { Project } from '../types/Project';
import { ProjectUser, ProjectUserStatus } from '../types/ProjectUser';

type ProjectUserTableRowProps = {
  rowProjectUser: ProjectUser;
  lockedAdmin: boolean;
  currentUserIsAdmin: boolean;
  changeProjectUserStatus: (userId: number, newStatus: ProjectUserStatus) => void;
  removeProjectUser: (userId: number) => void;
  projectId: number;
};
const ProjectUserTableRow = ({
  rowProjectUser,
  lockedAdmin,
  currentUserIsAdmin,
  changeProjectUserStatus,
  removeProjectUser,
  projectId,
}: ProjectUserTableRowProps): JSX.Element => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const deleteInvitationMutation = useMutation({
    ...endpointConfigs.deleteInvitation(rowProjectUser.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries(endpointConfigs.invitationsForProject(projectId));
      await queryClient.invalidateQueries(endpointConfigs.project(projectId));
      await queryClient.invalidateQueries(endpointConfigs.myProjects);
    },
  });
  const resendInvitationMutation = useMutation({
    ...endpointConfigs.invitationResend(rowProjectUser.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries(endpointConfigs.invitationsForProject(projectId));
      await queryClient.invalidateQueries(endpointConfigs.project(projectId));
      await queryClient.invalidateQueries(endpointConfigs.myProjects);
    },
  });

  const dropdownBodies = (setFloatingShown: (value: boolean) => void): Record<ProjectUserStatus, JSX.Element> => ({
    admin: (
      <div className="flex min-w-[250px] flex-col">
        <Button
          variant="squareList"
          leftIcon="settings"
          label={t('projects.share.modal.change_to_editor')}
          disabled={lockedAdmin || !currentUserIsAdmin}
          onClick={() => {
            setFloatingShown(false);
            changeProjectUserStatus(rowProjectUser.id, 'editor');
          }}
        />
        <Button
          variant="squareList"
          leftIcon="removeUser"
          label={t('projects.share.modal.remove')}
          disabled={lockedAdmin || !currentUserIsAdmin}
          onClick={() => {
            setFloatingShown(false);
            removeProjectUser(rowProjectUser.id);
          }}
        />
        {lockedAdmin && (
          <span className="px-4 pb-2 text-xs text-danger">{t('projects.share.modal.at_least_one_administrator')}</span>
        )}
      </div>
    ),
    editor: (
      <div className="flex flex-col">
        <Button
          variant="squareList"
          leftIcon="settings"
          label={t('projects.share.modal.change_to_administrator')}
          disabled={!currentUserIsAdmin}
          onClick={() => {
            setFloatingShown(false);
            changeProjectUserStatus(rowProjectUser.id, 'admin');
          }}
        />
        <Button
          variant="squareList"
          leftIcon="removeUser"
          label={t('projects.share.modal.remove')}
          disabled={!currentUserIsAdmin}
          onClick={() => {
            setFloatingShown(false);
            removeProjectUser(rowProjectUser.id);
          }}
        />
      </div>
    ),
    invited: (
      <div className="flex flex-col">
        <Button
          variant="squareList"
          leftIcon="send"
          label={t('projects.share.modal.resend_invitation')}
          onClick={() => {
            setFloatingShown(false);
            const promise = resendInvitationMutation.mutateAsync();

            conciseLinguaPromiseToast(t, promise, { translationPrefix: 'projects.share.resend_invitation_toast' });
          }}
        />
        <Button
          variant="squareList"
          leftIcon="removeUser"
          label={t('projects.share.modal.remove')}
          disabled={!currentUserIsAdmin}
          onClick={() => {
            setFloatingShown(false);
            const promise = deleteInvitationMutation.mutateAsync();

            conciseErrorLinguaPromiseToast(t, promise, {
              translationPrefix: 'projects.share.remove_invitation_toast',
            });
          }}
        />
      </div>
    ),
  });

  return (
    <tr className="hover h-14 border-b border-grey-4 bg-grey-3 text-sm">
      <td className="p-3">{rowProjectUser.name ?? ''}</td>
      <td className="p-3">{rowProjectUser.email ?? ''}</td>
      <td className="p-3">
        {/* eslint-disable-next-line no-nested-ternary */}
        {rowProjectUser.status === 'admin'
          ? t('projects.share.modal.administrator')
          : rowProjectUser.status === 'editor'
          ? t('projects.share.modal.editor')
          : t('projects.share.modal.invitation_sent')}
      </td>
      <td className="p-3">
        <Dropdown<HTMLButtonElement | HTMLAnchorElement> floatingSide="left" floatingVariant="tall">
          {({ toggleRef, toggleOnClick, setFloatingShown }) => ({
            toggle: <Button variant="tertiaryLight" icon="list" ref={toggleRef} onClick={toggleOnClick} />,
            body: dropdownBodies(setFloatingShown)[rowProjectUser.status],
          })}
        </Dropdown>
      </td>
    </tr>
  );
};

const invitationFormSchema = z.object({ email: z.string().email() });

type ProjectShareModalProps = {
  project: Project;
  visible: boolean;
  setVisible: (value: boolean) => void;
  projectUsers: ProjectUser[];
  currentUserId: number;
};

export const ProjectShareModal = ({
  project,
  visible,
  setVisible,
  projectUsers,
  currentUserId,
}: ProjectShareModalProps): JSX.Element => {
  const { t } = useTranslation();

  const lockedAdmin: boolean = projectUsers.filter(pu => pu.status === 'admin').length < 2;
  const currentUserIsAdmin = projectUsers.filter(pu => pu.id === currentUserId).pop()?.status === 'admin' ?? false;

  const queryClient = useQueryClient();
  const patchProjectMutation = useMutation({
    ...endpointConfigs.patchProject(project.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries(endpointConfigs.project(project.id));
      await queryClient.invalidateQueries(endpointConfigs.myProjects);
    },
  });

  const createInvitationMutation = useMutation({
    ...endpointConfigs.invitationCreateIfNeeded(project.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries(endpointConfigs.project(project.id));
      await queryClient.invalidateQueries(endpointConfigs.myProjects);
      await queryClient.invalidateQueries(endpointConfigs.invitationsForProject(project.id));
    },
  });

  return (
    <Modal visible={visible} setVisible={setVisible}>
      {{
        title: <span>{t('projects.share.modal.title', { projectName: project.name })}</span>,
        body: (
          <div className="flex flex-col gap-4">
            <span>
              {t('projects.share.modal.collaborators', {
                count: projectUsers.filter(pu => pu.status !== 'invited').length,
              })}
              {t('projects.share.modal.invited', {
                count: projectUsers.filter(pu => pu.status === 'invited').length,
              })}
            </span>
            <table>
              {projectUsers.map(pu => (
                <ProjectUserTableRow
                  key={`${pu.status}_${pu.id}`}
                  projectId={project.id}
                  rowProjectUser={pu}
                  lockedAdmin={lockedAdmin}
                  currentUserIsAdmin={currentUserIsAdmin}
                  changeProjectUserStatus={(userId: number, newStatus: ProjectUserStatus) => {
                    let newAdmins: number[] = [];
                    let newEditors: number[] = [];

                    if (newStatus === 'admin') {
                      newAdmins = project.admins.concat([userId]);
                      newEditors = project.editors.filter(id => id !== userId);
                    } else if (newStatus === 'editor') {
                      newEditors = project.editors.concat([userId]);
                      newAdmins = project.admins.filter(id => id !== userId);
                    }

                    const promise = patchProjectMutation.mutateAsync({
                      admins: newAdmins,
                      editors: newEditors,
                    });

                    conciseErrorLinguaPromiseToast(t, promise, {
                      translationPrefix: 'projects.share.edit_user_status',
                    });
                  }}
                  removeProjectUser={(userId: number) => {
                    const promise = patchProjectMutation.mutateAsync({
                      admins: project.admins.filter(id => id !== userId),
                      editors: project.editors.filter(id => id !== userId),
                    });

                    conciseErrorLinguaPromiseToast(t, promise, {
                      translationPrefix: 'projects.share.remove_user',
                    });
                  }}
                />
              ))}
            </table>
          </div>
        ),
        actions: (
          <div className="flex flex-col gap-4">
            <span>{t('projects.share.modal.invite_new_collaborators')}</span>

            <Formik
              initialValues={{ email: '' }}
              onSubmit={values => {
                const promise = createInvitationMutation.mutateAsync(values);

                conciseLinguaPromiseToast(t, promise, { translationPrefix: 'projects.share.send_invitation_toast' });
              }}
              validate={withZodSchema(invitationFormSchema)}
            >
              <Form>
                <div className="flex flex-row items-center gap-4 bg-grey-2 p-3">
                  <Icon icon="mail" />
                  <Field
                    name="email"
                    as="input"
                    className="h-full grow bg-white/0 text-sm focus:outline-none"
                    type="email"
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                    placeholder={t('projects.share.modal.invite_field_placeholder') as string}
                  />
                  <Button type="submit" variant="primary" label={t('projects.share.modal.send_invitation_button')} />
                </div>
                <ErrorMessage name="email">{msg => <span className="text-xs text-danger">{msg}</span>}</ErrorMessage>
              </Form>
            </Formik>
          </div>
        ),
      }}
    </Modal>
  );
};
