import { AxiosError } from 'axios';
import { TFunction } from 'next-i18next';
import { toast } from 'react-hot-toast';
import { LinguaToast, ToastProps } from 'src/features/ui/templates/Toast';
import { ErrorBody } from 'src/services/api/error';

export type LinguaToastParams = Omit<ToastProps, 't'>;

export const linguaToast = (
  params: LinguaToastParams,
  customOptions?: Parameters<typeof toast.custom>[1],
): ReturnType<typeof toast.custom> =>
  // eslint-disable-next-line react/jsx-props-no-spreading
  toast.custom(t => <LinguaToast t={t} {...params} />, customOptions);

export type LinguaPromiseToastParams<Error, Data> = {
  loading?: LinguaToastParams;
  success?: (data: Data) => LinguaToastParams;
  error?: (error: Error) => LinguaToastParams;
};

export const linguaPromiseToast = <Error, Data>(
  promise: Promise<Data>,
  params: LinguaPromiseToastParams<Error, Data>,
): void => {
  if (params.loading) {
    const id = linguaToast(params.loading, { duration: Infinity });
    promise.then(
      data => {
        if (params.success) {
          linguaToast(params.success(data), { id, duration: 5000 });
        }
      },
      error => {
        if (params.error) {
          linguaToast(params.error(error as Error), { id, duration: 5000 });
        }
      },
    );
  } else {
    promise.then(
      data => {
        if (params.success) {
          linguaToast(params.success(data), { duration: 5000 });
        }
      },
      error => {
        if (params.error) {
          linguaToast(params.error(error as Error), { duration: 5000 });
        }
      },
    );
  }
};

export type ConciseErrorLinguaPromiseToastParams = {
  translationPrefix: string;
};

export type ConciseLinguaPromiseToastParams = ConciseErrorLinguaPromiseToastParams & {
  excludeLoading?: boolean;
  excludeSuccess?: boolean;
  excludeError?: boolean;
};

export const conciseLinguaPromiseToast = <Data,>(
  t: TFunction,
  promise: Promise<Data>,
  params: ConciseLinguaPromiseToastParams,
): void =>
  linguaPromiseToast<AxiosError, Data>(promise, {
    loading: params.excludeLoading
      ? undefined
      : {
          title: t(`${params.translationPrefix}.loading.title`),
          text: t(`${params.translationPrefix}.loading.text`),
          variant: 'loading',
        },
    success: params.excludeSuccess
      ? undefined
      : () => ({
          title: t(`${params.translationPrefix}.success.title`),
          text: t(`${params.translationPrefix}.success.text`),
          variant: 'success',
        }),
    error: params.excludeError
      ? undefined
      : error => ({
          title: t(`${params.translationPrefix}.error.title`),
          text: t(`${params.translationPrefix}.error.text`, {
            error: (error.response?.data as ErrorBody).errors[0].detail,
          }),
          variant: 'danger',
        }),
  });

export const conciseErrorLinguaPromiseToast = <Data,>(
  t: TFunction,
  promise: Promise<Data>,
  params: ConciseErrorLinguaPromiseToastParams,
): void =>
  conciseLinguaPromiseToast(t, promise, {
    translationPrefix: params.translationPrefix,
    excludeSuccess: true,
    excludeLoading: true,
  });
