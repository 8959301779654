import classNames from 'classnames';
import { RefObject, useRef, useState } from 'react';
import { Floating, FloatingVariant } from 'src/features/ui/components/Floating';
import { useOutsideClick } from 'src/utils/hooks/useOutsideClick';

export type DropdownToggleProps<ToggleRefType extends HTMLElement> = {
  toggleRef: RefObject<ToggleRefType>;
  toggleOnClick: () => void;
  setFloatingShown: (value: boolean) => void;
};

export type DropdownProps<ToggleRefType extends HTMLElement> = {
  children: (props: DropdownToggleProps<ToggleRefType>) => { toggle: JSX.Element; body: JSX.Element };
  floatingVariant?: FloatingVariant;
  floatingSide?: 'left' | 'right';
};

export function Dropdown<ToggleRefType extends HTMLElement>({
  children,
  floatingVariant,
  floatingSide = 'right',
}: DropdownProps<ToggleRefType>): JSX.Element {
  const [floatingShown, setFloatingShown] = useState(false);
  const floatingRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<ToggleRefType>(null);

  // Hide floating when clicks happen outside the toggle and the floating
  useOutsideClick(floatingRef, event => {
    if (toggleRef.current && event.target && !toggleRef.current.contains(event.target as Node)) {
      setFloatingShown(false);
    }
  });

  const toggleFloating = () => {
    setFloatingShown(!floatingShown);
  };
  const toggleAndBody = children({ toggleRef, toggleOnClick: toggleFloating, setFloatingShown });

  return (
    <>
      {toggleAndBody.toggle}
      <Floating
        ref={floatingRef}
        variant={floatingVariant}
        className={classNames(floatingShown ? '' : 'hidden')}
        innerClassName={classNames(floatingSide === 'right' ? '' : 'right-0')}
      >
        {toggleAndBody.body}
      </Floating>
    </>
  );
}
