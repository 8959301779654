import { ReactNode } from 'react';
import { BreadcrumbsPipeline } from 'src/utils/types/BreadcrumbsPipeline';
import { TopBar } from './TopBar';

type LayoutProps = {
  children: ReactNode;
  breadcrumbsPipeline: BreadcrumbsPipeline;
};

export const Layout = ({ children, breadcrumbsPipeline }: LayoutProps): JSX.Element => (
  <div>
    <TopBar breadcrumbsPipeline={breadcrumbsPipeline} />
    <div className="flex min-h-screen min-w-fit flex-col items-center bg-grey-1 pt-[60px] text-white">{children}</div>
  </div>
);
