import classNames from 'classnames';
import { forwardRef } from 'react';

type StyleDef = string;

const styleDefs = {
  default: 'rounded-lg bg-grey-2 border border-solid border-grey-3',
  thick: 'rounded-lg bg-grey-2 border border-solid border-grey-3 px-2',
  thicker: 'rounded-lg bg-grey-2 border border-solid border-grey-3 px-3',
  tall: 'rounded-lg bg-grey-2 border border-solid border-grey-3 py-1',
  tallThicker: 'rounded-lg bg-grey-2 border border-solid border-grey-3 py-1 px-3',
} as const satisfies Record<string, StyleDef>;

export type FloatingVariant = keyof typeof styleDefs;

type FloatingProps = {
  variant?: FloatingVariant;
  fitToContainer?: boolean;
  className?: string;
  innerClassName?: string;
  children: JSX.Element;
};

export const Floating = forwardRef<HTMLDivElement, FloatingProps>(
  ({ variant = 'default', fitToContainer = false, className, innerClassName, children }, ref): JSX.Element => {
    const styles = styleDefs[variant];

    return (
      <div className={classNames('relative h-0', className)}>
        <div
          className={classNames(styles, 'absolute z-10 mt-1 flex flex-col', fitToContainer && 'w-full', innerClassName)}
          ref={ref}
        >
          {children}
        </div>
      </div>
    );
  },
);
