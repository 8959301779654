import { useState } from 'react';
import { Icon } from './Icon';

type RadioProps = {
  name: string;
  value: string;
  label: string;
  checked: boolean;
  setChecked: () => void;
};

const Radio = (props: RadioProps): JSX.Element => (
  <div className="flex h-10 flex-row items-center gap-2 text-sm text-white">
    {props.checked ? (
      <Icon icon="check" size="biggerer" className="text-primary" />
    ) : (
      <Icon icon="empty" size="biggerer" />
    )}
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
    <div
      className="flex h-full w-full cursor-pointer items-center whitespace-nowrap "
      onClick={() => props.setChecked()}
    >
      {props.label}
    </div>
    <input type="radio" className="hidden" name={props.name} value={props.value} checked={props.checked} readOnly />
  </div>
);

export type RadioOptionDetail = { label: string };

type RadioSetProps<OptionValue extends string> = {
  name: string;
  options: Record<OptionValue, RadioOptionDetail>;
  initialValue?: OptionValue;
  value?: OptionValue;
  onChange: (value: OptionValue) => void;
};

export const RadioSet = <OptionValue extends string>(props: RadioSetProps<OptionValue>): JSX.Element => {
  const [checkedOption, setCheckedOption] = useState<string | null>(props.initialValue ?? null);

  // Controlled component logic
  const controlledComponent: boolean = props.value !== null && props.value !== undefined;
  const realCheckedOption = controlledComponent ? props.value : checkedOption;
  const realSetCheckedOption = controlledComponent ? () => {} : setCheckedOption;

  return (
    <>
      {(Object.entries(props.options) as [OptionValue, RadioOptionDetail][]).map(([value, detail]) => (
        <Radio
          name={props.name}
          value={value}
          label={detail.label}
          checked={realCheckedOption === value}
          setChecked={() => {
            props.onChange(value);
            realSetCheckedOption(value);
          }}
          key={value}
        />
      ))}
    </>
  );
};
