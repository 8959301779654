import { useQuery } from '@tanstack/react-query';
import { ProjectLanguage } from 'src/features/projectlanguages/types/ProjectLanguage';
import { ProgressRing } from 'src/features/ui/components/ProgressRing';
import endpointConfigs from 'src/services/api/endpointConfigs';

type LanguageSummaryBoxProps = { projectLanguage: ProjectLanguage };

export const LanguageSummaryBox = ({ projectLanguage }: LanguageSummaryBoxProps): JSX.Element => {
  const { data } = useQuery(endpointConfigs.language(projectLanguage.language));

  return data ? (
    <div className="flex basis-3/12 flex-col gap-1 border border-solid border-grey-3 px-5 pb-3 pt-2">
      <div className="text-base">{data.name}</div>
      <div className="flex flex-row gap-2 text-xs text-grey-5">
        <ProgressRing
          progress={projectLanguage.translation_coverage.complete_percentage}
          svgClassName="fill-primary"
          size="20"
          strokePercentage={0.4}
        />
        {(projectLanguage.translation_coverage.complete_percentage * 100).toFixed(0)}% completed
      </div>
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};

type LanguageSummaryBoxSetProps = { projectLanguages: ProjectLanguage[] };

export const LanguageSummaryBoxSet = ({ projectLanguages }: LanguageSummaryBoxSetProps): JSX.Element => (
  <div className="flex flex-row">
    {projectLanguages.map(pl => (
      <LanguageSummaryBox projectLanguage={pl} key={pl.id} />
    ))}
  </div>
);
