import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { MouseEventHandler } from 'react';
import { fullName } from 'src/features/users/functions/fullName';
import endpointConfigs from 'src/services/api/endpointConfigs';
import routeConfigs from 'src/services/routeConfigs';
import { NonNullableProperties } from 'src/utils/types/NonNullableProperties';
import { LocaleFileStats } from '../types/LocaleFile';

type LocaleFileStatsWithUser = NonNullableProperties<LocaleFileStats, 'latest_editor'>;

type LocaleFileTableRowWithUserProps = { lf: LocaleFileStatsWithUser; onClick: MouseEventHandler };
const LocaleFileTableRowWithUser = ({ lf, onClick }: LocaleFileTableRowWithUserProps) => {
  const { data } = useQuery(endpointConfigs.user(lf.latest_editor));
  return (
    <tr onClick={onClick} className="h-14 cursor-pointer border-b border-grey-4 bg-grey-3 text-sm hover:bg-grey-4">
      <td className="p-3">{lf.path}</td>
      <td className="p-3">{lf.number_of_keys}</td>
      <td className="p-3">{lf.latest_edition_timestamp}</td>
      <td className="p-3">{data ? fullName(data) : 'loading'}</td>
    </tr>
  );
};

type LocaleFileTableRowNoUserProps = { lf: LocaleFileStats; onClick: MouseEventHandler };
const LocaleFileTableRowNoUser = ({ lf, onClick }: LocaleFileTableRowNoUserProps) => {
  const { t } = useTranslation();
  return (
    <tr onClick={onClick} className="h-14 cursor-pointer border-b border-grey-4 bg-grey-3 text-sm hover:bg-grey-4">
      <td className="p-3">{lf.path}</td>
      <td className="p-3">{lf.number_of_keys}</td>
      <td className="p-3">{new Date(lf.latest_edition_timestamp).toLocaleString()}</td>
      <td className="p-3">{t('localefiles.table.no_last_editor')}</td>
    </tr>
  );
};

type LocaleFileTableProps = { localefiles: LocaleFileStats[] };
export const LocaleFileTable = ({ localefiles }: LocaleFileTableProps): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <table>
      <thead>
        <tr className="text-xs text-grey-5">
          <td className="p-3">{t('localefiles.table.path')}</td>
          <td className="p-3">{t('localefiles.table.number_of_keys')}</td>
          <td className="p-3">{t('localefiles.table.last_edited_timestamp')}</td>
          <td className="p-3">{t('localefiles.table.last_editor')}</td>
        </tr>
      </thead>
      <tbody>
        {localefiles.map(lf =>
          lf.latest_editor ? (
            <LocaleFileTableRowWithUser
              lf={lf as LocaleFileStatsWithUser}
              key={lf.id}
              // eslint-disable-next-line no-void
              onClick={() => void router.push(routeConfigs.editor(lf.project, lf.id))}
            />
          ) : (
            <LocaleFileTableRowNoUser
              lf={lf}
              key={lf.id}
              // eslint-disable-next-line no-void
              onClick={() => void router.push(routeConfigs.editor(lf.project, lf.id))}
            />
          ),
        )}
      </tbody>
    </table>
  );
};
