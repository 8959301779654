import { useQueries, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { LanguageSummaryBoxSet } from 'src/features/languages/components/LanguageSummaryBox';
import { LocaleFileTable } from 'src/features/localefiles/templates/LocaleFileTable';
import { Button } from 'src/features/ui/components/Button';
import { AvatarSet } from 'src/features/users/components/AvatarSet';
import { fullName } from 'src/features/users/functions/fullName';
import { initials } from 'src/features/users/functions/initials';
import { User } from 'src/features/users/types/User';
import endpointConfigs from 'src/services/api/endpointConfigs';
import routeConfigs from 'src/services/routeConfigs';
import { filterUndefined } from 'src/utils/functions/filterUndefined';
import { ProjectShareModal } from './ProjectShareModal';
import { Project } from '../types/Project';
import { ProjectUser } from '../types/ProjectUser';

type ProjectCardProps = { project: Project };

export const ProjectCard = ({ project }: ProjectCardProps): JSX.Element => {
  const { t } = useTranslation();

  const editorsQueries = useQueries({ queries: project.editors.map(pk => endpointConfigs.user(pk)) });
  const editors = editorsQueries.every(query => query.isSuccess)
    ? (editorsQueries.map(query => query.data) as User[])
    : [];

  const adminsQueries = useQueries({ queries: project.admins.map(pk => endpointConfigs.user(pk)) });
  const admins = adminsQueries.every(query => query.isSuccess)
    ? (adminsQueries.map(query => query.data) as User[])
    : [];

  const invitationsQuery = useQuery(endpointConfigs.invitationsForProject(project.id));
  const invitations = invitationsQuery.data ?? [];

  const projectUsers = admins
    .map(
      user =>
        ({
          id: user.id,
          name: fullName(user),
          initials: initials(user),
          email: user.email,
          status: 'admin',
        } as ProjectUser),
    )
    .concat(
      editors.map(user => ({
        id: user.id,
        name: fullName(user),
        initials: initials(user),
        email: user.email,
        status: 'editor',
      })),
    )
    .concat(invitations.map(invitation => ({ id: invitation.id, email: invitation.email, status: 'invited' })));

  const meQuery = useQuery(endpointConfigs.currentUser);

  const localeFilesQuery = useQuery(endpointConfigs.localeFilesStatsForProject(project.id));

  const [shareModalVisible, setShareModalVisible] = useState(false);

  return (
    <div className="flex flex-col gap-5 rounded-lg border border-solid border-grey-3 bg-grey-2 px-8 pb-10 pt-5">
      <div className="flex justify-between">
        <span className="text-2xl">{project.name}</span>
        <div className="flex gap-3">
          <AvatarSet labels={filterUndefined(projectUsers.map(pu => pu.initials))} />
          <Button
            variant="secondary"
            label={t('projects.share.call_to_action')}
            onClick={() => setShareModalVisible(true)}
          />
          {meQuery.data && (
            <ProjectShareModal
              project={project}
              visible={shareModalVisible}
              setVisible={setShareModalVisible}
              projectUsers={projectUsers}
              currentUserId={meQuery.data.id}
            />
          )}
          <Button variant="tertiary" icon="settings" url={routeConfigs.projectPk(project.id)} />
        </div>
      </div>
      <LanguageSummaryBoxSet projectLanguages={project.languages} />

      {localeFilesQuery.isSuccess && <LocaleFileTable localefiles={localeFilesQuery.data} />}
    </div>
  );
};
