import classNames from 'classnames';
import { ProgressRing } from './ProgressRing';

type SpinnerProps = {
  fullScreen?: boolean;
  className?: string;
  size?: string;
};

export const Spinner = ({ className, fullScreen = false, size }: SpinnerProps): JSX.Element => (
  <div
    className={classNames(
      fullScreen && 'min-w-screen flex min-h-screen items-center justify-center bg-grey-1',
      className,
    )}
  >
    <ProgressRing
      progress={0.25}
      className="-auto animate-spin"
      size={size ?? '128'}
      strokePercentage={0.2}
      svgClassName="fill-grey-5"
    />
  </div>
);
