import classNames from 'classnames';

type AvatarProps = { label?: string; className?: string };

const Avatar = ({ label, className }: AvatarProps): JSX.Element => (
  <div
    className={classNames(
      'flex items-center justify-center',
      'border border-solid border-grey-2',
      'bg-grey-3',
      'text-xs',
      'rounded-full',
      'h-10 w-10',
      className,
    )}
  >
    {label}
  </div>
);

type AvatarSetProps = { labels: Array<string> };

export const AvatarSet = ({ labels }: AvatarSetProps): JSX.Element => (
  <div className="flex flex-row">
    {labels.map(label => (
      <Avatar key={label} label={label} className="-ml-1 first:ml-0" />
    ))}
  </div>
);
