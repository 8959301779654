import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { envConfig } from 'envConfig';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Fragment, ReactNode } from 'react';
import { fullName } from 'src/features/users/functions/fullName';
import endpointConfigs from 'src/services/api/endpointConfigs';
import routeConfigs from 'src/services/routeConfigs';
import { BreadcrumbsPipeline } from 'src/utils/types/BreadcrumbsPipeline';
import { Button } from '../components/Button';
import { Dropdown } from '../components/Dropdown';
import { InternalLink } from '../components/InternalLink';

type TopBarProps = {
  rightElement?: ReactNode;
  breadcrumbsPipeline: BreadcrumbsPipeline;
};

export const TopBar = ({ rightElement, breadcrumbsPipeline }: TopBarProps): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation();

  const { data: userData } = useQuery({ ...endpointConfigs.currentUser });
  const logoutMutation = useMutation({
    ...endpointConfigs.logout,
    onSuccess: async () => {
      await router.push(routeConfigs.login);
    },
  });

  const urlParts = router.asPath.split('/').filter(v => v.length > 0);
  const breadcrumbsEndpointConfigs = breadcrumbsPipeline.urlToQueries(urlParts);
  const breadcrumbsQueries = useQueries({ queries: breadcrumbsEndpointConfigs });
  const breadcrumbs = breadcrumbsQueries.every(query => query.isSuccess)
    ? breadcrumbsPipeline.queriesResultsToBreadcrumbs(
        breadcrumbsQueries.map(query => query.data),
        urlParts,
      )
    : [];

  return (
    <header className="fixed top-0 z-50 flex min-h-[60px] w-full items-center border-b border-grey-3 bg-grey-2 text-white">
      <div className="min-h-[60px] w-[180px] border-r border-grey-3">Logo {envConfig.environment}</div>
      <div className="flex flex-grow gap-5 px-5 text-xl">
        {breadcrumbs.map((bc, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`${bc.label}_${i}`}>
            {bc.href ? (
              <InternalLink variant="breadcrumb" href={bc.href}>
                {bc.label}
              </InternalLink>
            ) : (
              <span>{bc.label}</span>
            )}
            {i !== breadcrumbs.length - 1 && <span className="text-grey-5">/</span>}
          </Fragment>
        ))}
      </div>
      {rightElement && <div>{rightElement}</div>}
      <div className="pr-8 ">
        <Dropdown<HTMLAnchorElement | HTMLButtonElement> floatingVariant="thick" floatingSide="left">
          {({ toggleRef, toggleOnClick, setFloatingShown }) => ({
            toggle: (
              <Button
                ref={toggleRef}
                variant="topBar"
                rightIcon="userCircle"
                onClick={toggleOnClick}
                label={(userData && fullName(userData)) || ''}
              />
            ),
            body: (
              <>
                <Button
                  variant="squareList"
                  leftIcon="user"
                  label={t('layout.profile')}
                  url={routeConfigs.profile}
                  onClickLink={() => {
                    setFloatingShown(false);
                  }}
                />
                <Button
                  variant="squareListDanger"
                  leftIcon="logout"
                  label={t('layout.logout')}
                  onClick={() => {
                    logoutMutation.mutate();
                    setFloatingShown(false);
                  }}
                />
              </>
            ),
          })}
        </Dropdown>
      </div>
    </header>
  );
};
