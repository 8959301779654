import { RefObject, useEffect } from 'react';

/**
 * Hook that calls back when the user clicks outside of the passed ref
 * Thanks to https://stackoverflow.com/a/42234988
 */
export function useOutsideClick<T extends HTMLElement>(ref: RefObject<T>, callback: (event: MouseEvent) => void): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, callback]);
}
