export type ProgressRingProps = {
  progress: number;
  className?: string;
  svgClassName?: string;
  strokePercentage?: number;
  size?: string;
};

export const ProgressRing = ({
  progress,
  className,
  svgClassName = 'fill-primary',
  strokePercentage = 0.1,
  size,
}: ProgressRingProps): JSX.Element => {
  const outerRadius = 0.5;
  const innerRadius = outerRadius * (1 - strokePercentage);

  const progressAngle = Math.PI / 2 - 2 * Math.PI * progress;
  const arcEndPoint = [Math.cos(progressAngle) * outerRadius, -Math.sin(progressAngle) * outerRadius];

  return (
    <svg height={size} width={size} viewBox="-0.5 -0.5 1 1" className={className}>
      <defs>
        <mask id="theMask">
          <circle r={outerRadius} cx="0" cy="0" fill="white" />
          <circle r={innerRadius} cx="0" cy="0" fill="black" />
        </mask>
      </defs>

      <circle r={outerRadius} cx="0" cy="0" className="fill-grey-4" mask="url(#theMask)" />
      <path
        className={svgClassName}
        d={`M 0 ${-outerRadius} 
              A ${outerRadius} ${outerRadius} 0 ${progress > 0.5 ? 1 : 0} 1 ${arcEndPoint[0]} ${arcEndPoint[1]}
              L 0 0
              Z`}
        mask="url(#theMask)"
      />
    </svg>
  );
};
