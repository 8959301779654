import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { ReactElement, useState } from 'react';
import { ProjectCard } from 'src/features/projects/templates/ProjectCard';
import { Project } from 'src/features/projects/types/Project';
import { Button } from 'src/features/ui/components/Button';
import { Dropdown } from 'src/features/ui/components/Dropdown';
import { RadioOptionDetail, RadioSet } from 'src/features/ui/components/RadioSet';
import { Layout } from 'src/features/ui/layout/Layout';
import endpointConfigs from 'src/services/api/endpointConfigs';
import { LoginRequired } from 'src/services/LoginRequired';
import routeConfigs from 'src/services/routeConfigs';
import { ssrProvider } from 'src/services/ssrProvider';

export type ProjectSortingOption = 'alphaAsc' | 'alphaDesc' | 'lastAdded' | 'lastModified';

export type ProjectSortingState = ProjectSortingOption;

export const projectSortingRadioOptionDetails = (
  t: (key: string) => string,
): Record<ProjectSortingOption, RadioOptionDetail> =>
  ({
    alphaAsc: { label: t('projects.list_page.sorting_dropdown.alphabetically_asc') },
    alphaDesc: { label: t('projects.list_page.sorting_dropdown.alphabetically_desc') },
    lastAdded: { label: t('projects.list_page.sorting_dropdown.last_added_first') },
    lastModified: { label: t('projects.list_page.sorting_dropdown.last_modified_first') },
  } as const satisfies Record<ProjectSortingOption, RadioOptionDetail>);

export const projectSortingFunctions = {
  alphaAsc: (a: Project, b: Project) => (a.name > b.name ? 1 : -1),
  alphaDesc: (a: Project, b: Project) => (a.name < b.name ? 1 : -1),
  lastAdded: (a: Project, b: Project) => (new Date(a.creation_timestamp) < new Date(b.creation_timestamp) ? 1 : -1),
  lastModified: (a: Project, b: Project) =>
    new Date(a.latest_edition_timestamp ?? 0) < new Date(b.latest_edition_timestamp ?? 0) ? 1 : -1,
} as const satisfies Record<ProjectSortingOption, (a: Project, b: Project) => number>;

export default function Page(): JSX.Element {
  const { t } = useTranslation();

  const [projectSortingState, setProjectSortingState] = useState<ProjectSortingState>('lastModified');

  const { data: projectsData } = useQuery(endpointConfigs.myProjects);
  const sortedProjects = projectsData ? projectsData.sort(projectSortingFunctions[projectSortingState]) : [];

  return (
    <div className="container py-10 px-10">
      <div className="mb-5 flex justify-between text-xl">
        <span>{t('projects.list_page.projects_number', { count: projectsData?.length })}</span>
        <div className="flex gap-3">
          <Button variant="primary" label={t('projects.list_page.new_button')} url={routeConfigs.projectNew} />

          <div>
            <Dropdown<HTMLAnchorElement | HTMLButtonElement> floatingVariant="thick" floatingSide="left">
              {({ toggleRef, toggleOnClick }) => ({
                toggle: <Button ref={toggleRef} variant="tertiary" icon="sort" onClick={toggleOnClick} />,
                body: (
                  <RadioSet
                    name="projectSorting"
                    options={projectSortingRadioOptionDetails(t)}
                    initialValue="lastModified"
                    onChange={value => setProjectSortingState(value)}
                  />
                ),
              })}
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        {sortedProjects.map(proj => (
          <ProjectCard key={proj.id} project={proj} />
        ))}
      </div>
    </div>
  );
}

Page.getLayout = function getLayout(page: ReactElement) {
  return (
    <LoginRequired>
      <Layout
        breadcrumbsPipeline={{
          urlToQueries: () => [],
          queriesResultsToBreadcrumbs: () => [{ label: 'My Projects' }],
        }}
      >
        {page}
      </Layout>
    </LoginRequired>
  );
};

export const getServerSideProps = ssrProvider({});
